import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type RecipeState  = {
    timelineStartDate: Date;
    timelineEndDate: Date;
}

// Calculate 15 days from now
const endDate = new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000));
// Calculate 6 months before that
const startDate = new Date(new Date(endDate).setMonth(endDate.getMonth() - 6));

// new Date().getTime() + (15 * 24 * 60 * 60 * 1000) is 15 days from now
const initialState: RecipeState = {
    timelineStartDate: startDate,
    timelineEndDate: endDate
}

export const recipeSlice = createSlice({
    name: 'recipes',
      initialState,
      reducers: {
        updateTimelineStartDate: (state, { payload }: PayloadAction<Date>) => ({
          ...state,
          timelineStartDate: payload 
        }),
        updateTimelineEndDate: (state, { payload }: PayloadAction<Date>) => ({
          ...state,
          timelineEndDate: payload
        })
    }
})